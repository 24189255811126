import styled from 'styled-components';

export const ContactContainer = styled.section`
  padding: 50px 20px;
  text-align: center;
  background: 
`;

export const ContactTitle = styled.h2`
  font-size: 2.5em;
  margin-bottom: 20px;
`;

export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContactInput = styled.input`
  font-size: 1em;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 1px solid #ddd;
  width: 300px;
`;

export const ContactButton = styled.button`
  font-size: 1em;
  padding: 10px 20px;
  color: white;
  background-color: #333;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #555;
  }
`;
