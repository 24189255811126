import './About.css'
import React from 'react';
import { AboutContainer, AboutTitle, AboutText } from './AboutStyles';

const About = () => {
  // Replace these URLs with your actual image URLs
  const imageUrls = [
    'aws.png',
    'docker.png',
    'jenkins.png',
    'owasp.png',
    'openai.png',
    // Add more images as needed
  ];

  return (
    <AboutContainer id="about">
      <AboutTitle>About Us</AboutTitle>
      <AboutText>
      QuantumMind Software, a leader in AI and web development, excels in creating advanced digital solutions. Our expertise in cloud computing, particularly with Amazon Web Services, provides reliable, scalable hosting. We're proficient in Continuous Integration and Deployment, ensuring efficient, high-quality software development. We manage projects from start to finish, delivering custom, cutting-edge solutions for client success in the digital world.
      </AboutText>
      <div className="image-scroller">
        {imageUrls.map((url, index) => (
          <img key={index} src={url} alt={`Scroller Image ${index + 1}`} />
        ))}
      </div>
    </AboutContainer>
  );
};

export default About;
