import './Product.css';
import React from 'react';
import { ProductContainer, ProductTitle, ProductDescription, ProductLink } from './ProductStyles';

const Product = () => {
  return (
    <ProductContainer id="product">
    <ProductTitle>Our Products</ProductTitle>
    <ProductDescription>
      <div class="product-group">
        <div class="product"><img class="product-logo" src='global_interviewer.png'/><div className='product-name'>Global Interviewer</div></div>
        <div class="product-description">Discover Global Interviewer, our advanced AI-driven platform changing how professionals network. With no sign-ups or costs, engage in chats to make your mark. It's a unique space to highlight your skills, experiences, and personality interactively. Ideal for job seekers, experts, or those expanding their network, Global Interviewer offers global exposure. Dive into the future of networking – connect, showcase, and thrive with Global Interviewer.</div>
      </div>
    </ProductDescription>
    <ProductLink href="https://www.global-interviewer.com" target="_blank">Have an interview online</ProductLink>
    <ProductLink href="https://t.me/global_interviewer_bot" target="_blank">Have an interview on Telegram</ProductLink>
    <ProductDescription>
      <div class="product-group">
        <div class="product"><img class="product-logo" src='global_recruiter.png'/><div className='product-name'>Global Recruiter</div></div>
        <div class="product-description">Introducing Global Recruiter, the innovative Telegram bot designed to simplify recruitment. Access our assistant to search our vast database for ideal candidates. Pay per search, giving you control over costs. Whether seeking specific talent or exploring the job market, Global Recruiter offers an efficient, cost-effective way to connect with professionals daily. Elevate your recruitment strategy with our precision-driven, on-demand service.</div>
      </div>
    </ProductDescription>
    </ProductContainer>
  );
};

export default Product;
