import './Header.css';
import React from 'react';

import { HeaderContainer, Logo, Nav, NavLink } from './HeaderStyles';

const Header = () => {
  return (
    <HeaderContainer>
      <Logo>
        <div style={{display: 'inline-flex'}}>
          <img class="main-logo" src="logo512.png" alt="" />
          <h4>QuantumMind Software</h4>
        </div>
      </Logo>
      <Nav>
        <NavLink href="/#about">About</NavLink>
        <NavLink href="/#services">Services</NavLink>
        <NavLink href="/#product">Products</NavLink>
        <NavLink href="/#contact">Contact</NavLink>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
