import styled from 'styled-components';

export const AboutContainer = styled.div`
  padding: 50px 20px;
  text-align: center;
  background: #37547f; // Darker blue for the about section background
`;

export const AboutTitle = styled.h2`
  font-size: 2.5em;
  color: #0ec8e9; // Bright blue for titles
  margin-bottom: 20px;
`;

export const AboutText = styled.p`
  font-size: 1.2em;
  margin: 0px 8%;
  color: #fff; // Light green for text
`;
