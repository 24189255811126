import './Services.css';
import React from 'react';
import { ServicesContainer, ServiceTitle, ServiceDescription } from './ServicesStyles';

const Services = () => {
  return (
    <ServicesContainer id="services">
      <div className='services-group'>
        <div className='services-title'>Services</div>
        <div className='services-card'>
          <ServiceTitle>AI Solutions</ServiceTitle>
          <ServiceDescription>QuantimMind Software PTY (LTD) is at the forefront of integrating cutting-edge AI solutions across a multitude of applications. Our expertise lies in the deployment of sophisticated AI tools and image classifiers, tailored to enhance the efficiency and capabilities of various systems. A standout service we offer is the integration of AI Assistants, which can seamlessly interact with both internal and external systems, particularly those equipped with an API. Our team excels in configuring these assistants to engage with your systems at an API level, ensuring a streamlined and intelligent interface. This integration not only optimizes operational workflows but also significantly elevates user experience, leveraging the power of AI to meet diverse business needs.</ServiceDescription>
        </div>
        <div className='services-card'>
          <ServiceTitle>Web Development</ServiceTitle>
          <ServiceDescription>Specializing in modern and responsive web development services, our company brings over a decade of expertise to the forefront of web application development. We have honed our skills in crafting serverless web apps, a strategy that not only enhances performance but also significantly optimizes costs. Our commitment to staying abreast with the latest technological advancements is evident in our adoption of Continuous Deployment and Continuous Integration (CD/CI) methodologies. This approach enables us to seamlessly adapt to our clients' evolving needs, ensuring their web solutions are always up-to-date, efficient, and perfectly aligned with their business objectives. Our track record in delivering dynamic, user-friendly, and scalable web applications positions us as a trusted partner in the ever-changing digital landscape.</ServiceDescription>
        </div>
        <div className='services-card'>
          <ServiceTitle>Cloud Services</ServiceTitle>
          <ServiceDescription>Cloud Services are an integral part of our company's offerings, designed to propel the growth of our clients through highly scalable solutions. We specialize in hosting and managing a robust cloud computing environment, inclusive of cloud storage and infrastructure services. Our approach is tailored to ensure that scalability does not compromise cost-effectiveness. We prioritize cost management, enabling businesses to expand their digital footprint without incurring unnecessary expenses. Security and compliance are at the forefront of our services, ensuring that our clients' data remains secure and their operations are in line with regulatory standards. This holistic approach to cloud services is aimed at providing a seamless, efficient, and secure digital transformation journey for our clients.</ServiceDescription>
        </div>
      </div>
    </ServicesContainer>
  );
};

export default Services;
