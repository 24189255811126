import './GDPRPolicy.css';
import React from 'react';
import { ServicesContainer, ServiceTitle, ServiceDescription } from './ServicesStyles';

const GDPRPolicy = () => (
  <div className="gdpr-policy">
    <ServicesContainer id="services">
      <div className='services-group'>
        <div className='services-title'>GDPR Compliance Statement</div>
        <div className='services-card'>
          <ServiceTitle>Purpose of This Website</ServiceTitle>
          <ServiceDescription>This website is designed solely for marketing purposes. Our goal is to provide information about our products and services to interested parties.</ServiceDescription>
        </div>
        <div className='services-card'>
          <ServiceTitle>Data Collection and Privacy</ServiceTitle>
          <ServiceDescription><p>We respect your privacy. In alignment with the General Data Protection Regulation (GDPR), we wish to inform you that:</p>
            <ul>
              <li><strong>No Personal Data Collection:</strong> This website does not collect any form of personal data from its visitors.</li>
              <li><strong>Cookies and Tracking:</strong> We do not use cookies or any tracking mechanisms that collect personal data.</li>
            </ul>
            <p>For any queries regarding our data policy, please contact us at <a href="mailto:hv.joolitt@gmail.com">hv.joolitt@gmail.com</a>.</p>
          </ServiceDescription>
        </div>
      </div>
    </ServicesContainer>
  </div>
);

export default GDPRPolicy;
