import styled from 'styled-components';

export const ProductContainer = styled.section`
  padding: 50px 20px;
  text-align: center;
  background: #011a38; // Dark blue for product section background
`;

export const ProductTitle = styled.h2`
  font-size: 2.5em;
  color: #79fce6; // Light teal for product titles
  margin-bottom: 20px;
`;

export const ProductDescription = styled.p`
  font-size: 1.2em;
  color: #fff; // Light green for product descriptions
`;

export const ProductLink = styled.a`
  background-color: #037ebd;
  display: inline-block;
  margin: 12px 12px;
  color: white;
  colaaor: #011a38; // Dark blue for links
  text-decoration: none;
  border: 2px solid #011a38; // Dark blue for border
  padding: 10px 20px;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: #011a38; // Dark blue for hover background
    color: #77fce6; // Light teal for hover text
  }
`;