import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import About from './components/About';
import Services from './components/Services';
import Product from './components/Product';
import Contact from './components/Contact';
import GDPRPolicy from './components/GDPRPolicy'; // Import the GDPRPolicy component
import './App.css';

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={
          <>
            <About />
            <Services />
            <Product />
            <Contact />
          </>
        } />
        <Route path="/policy" element={<GDPRPolicy />} />
        {/* You can add more routes here */}
      </Routes>
    </Router>
  );
};

export default App;
