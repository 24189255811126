import React from 'react';
import { ContactContainer, ContactTitle, ContactForm, ContactInput, ContactButton } from './ContactStyles';

const Contact = () => {
  return (
    <ContactContainer id="contact">
      <ContactTitle>Contact Us</ContactTitle>
      
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <img src="Pic.png" alt="Director" style={{ borderRadius: '50%', width: '150px', height: '150px', objectFit: 'cover' }} />
      </div>

      <div>
        Director: <b>Heinrich Venter</b><br/>
        Email: <b>heinrich.venter@quantummindsoftware.com</b><br/>
        Number: <b>+27731922566</b><br/>
      </div>

      <div style={{margin: '16px'}}>     
        <a href="/policy">
          <ContactButton type="button">View our GDPR Policy</ContactButton>
        </a>
      </div>

      QuantumMind Software PTY (LTD) (2013/146115/07)
    </ContactContainer>
  );
};

export default Contact;
