import styled from 'styled-components';

export const ServicesContainer = styled.section`
  display: flex;
  justify-content: center;
  padding: 50px 8%;
  background: #037ebd; // Slightly darker blue for the services section background
  height: 100%;
`;

export const ServiceCard = styled.div`
  margin: 0 20px;
  text-align: center;
  max-width: 20%;
  min-width: 360px;
`;

export const ServiceTitle = styled.h3`
  font-size: 2em;
  color: #0ec8e9; // Bright blue for service titles
  margin-bottom: 15px;
`;

export const ServiceDescription = styled.p`
  font-size: 1.2em;
  color: #fff; // Light green for service descriptions
`;

