import styled from 'styled-components';

export const HeaderContainer = styled.header`
  background: #046eac; // Medium blue background
  padding: 20px 0;
  text-align: center;
  width: 100%;
`;

export const Logo = styled.h1`
  color: #77fce6; // Light teal for the logo
  font-size: 2em;
  margin: 0;
`;

export const Nav = styled.nav`
  margin-top: 20px;
`;

export const NavLink = styled.a`
  color: #fff; // Light green for navigation links
  margin: 0 15px;
  text-decoration: none;
  font-size: 1.2em;
  &:hover {
    color: #77fce6; // Light teal for hover state
    text-decoration: underline;
  }
`;
